import React, {useState} from 'react';
import {useRoutes, useNavigate} from 'react-router-dom';
import routes from './routes';
import DomSizesContext from './contexts/DomSizes';
import {IntlProvider} from 'react-intl';
import messages from './languages/messages';
import AppLocalesStorage from './languages/AppLocalesStorage';
import './styles/App.css';
import './styles/adaptive.css';
import TokenStorage from "./api/TokenStorage";

function App() {
    const appRoutes = useRoutes(routes);
    const [locale] = useState(AppLocalesStorage.getLocale());
    const [domSizes, setDomSizes] = useState({
        headerHeight: 0
    });
    const navigate = useNavigate();

    if (window.location.pathname.includes('app') && TokenStorage.getAccessToken() === '') {
        navigate('/sign-up-contacts', { replace: true });
    }

    return (
        <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={AppLocalesStorage.getDefaultLocale()}>
            <DomSizesContext.Provider value={{domSizes, setDomSizes}}>
                <div className="App">
                    {appRoutes}
                </div>
            </DomSizesContext.Provider>
        </IntlProvider>
    );
}

export default App;
