import { useParams } from 'react-router-dom';
import style from './Consultation.module.css';
import getEnvVars from '../../api/environment';
import { useState } from 'react';

const Consultation = () => {
    const {id} = useParams();
    const env = getEnvVars();
    const userMail = JSON.parse(localStorage.getItem('user')).email;
    const lang = localStorage.getItem('locale');
    const [messageForFeedback, setMessageForFeedback] = useState("");

    const sendOrder = () => {
        fetch(
            `${env.apiUrl}/api/consultations/${id}`, 
            {
                method: 'POST',
                body: {
                    email: userMail,
                },
            }
        )
            .then((resp) => {
                setMessageForFeedback(() => {
                    if(lang === "ru") return "Заявка успешно отправлена";
                    else return "The application has been successfully submitted"; 
                });
                
                setTimeout(() => {
                    window.location.replace('/');
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                setMessageForFeedback(() => {
                    if(lang === "ru") return "Возникла проблема при отправке заявки, попробуйте ещё или отправьте заявку позже";
                    else return "There was a problem when submitting the application, try again or send the application later"; 
                });               
            });
    };

    return (
        <main>  
        {   messageForFeedback !== "" &&
            <h2 className={style.msg}>
                {messageForFeedback}
            </h2>
        }
            <h1 className={style.title}>
            {
                lang === 'ru' 
                ? "Заказать консультацию?"
                : "Order a consultation?"
            }
            </h1>
            <button
                className={style.sendOrder}
                onClick={() => sendOrder()}
            >
            {
                lang === 'ru' 
                ? "Отправить заказ"  
                : "Send an order"           
            }
            </button>
        </main>
    );
};

export default Consultation;