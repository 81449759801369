import React, {useState, useContext, useEffect} from 'react';
import DomSizesContext from '../../../contexts/DomSizes';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import LangSelect from '../../../components/LangSelect/LangSelect';
import TokenStorage from '../../../api/TokenStorage';
import Logo from '../../../components/Logo/Logo';
import './ChildrenTest.css';
import {useGet, usePost} from "../../../api/request";
import AppLocalesStorage from "../../../languages/AppLocalesStorage";
import {BallTriangle} from "react-loader-spinner";
import messages from '../../../languages/messages';

const ChildrenTest = () => {

    const getU = useGet();
    const postU = usePost();
    const languageFromLocalStore = AppLocalesStorage.getLocale();

    const [isLoading, setIsLoading] = useState(false);

    const [infoTextId, setInfoTextId] = useState('thankForTest');
    const [errMsgVisible, setErrMsgVisible] = useState(false);
    const [isInputCorrect, setIsInputCorrect] = useState(false);

    const navigate = useNavigate();
    const domSizesStore = useContext(DomSizesContext);

    const [countForActiveButton, setCountForActiveButton] = useState(1);

    const [answers, setAnswers] = useState([
        {
            id: 1,
            backgroundColor: '#F44336',
            order: 0,
            isDisabled: false
        },
        {
            id: 2,
            backgroundColor: '#FDD835',
            order: 0,
            isDisabled: false
        },
        {
            id: 3,
            backgroundColor: '#2196F3',
            order: 0,
            isDisabled: false
        },
        {
            id: 4,
            backgroundColor: '#8BC34A',
            order: 0,
            isDisabled: false
        },
        {
            id: 5,
            backgroundColor: '#E91E63',
            order: 0,
            isDisabled: false
        },
        {
            id: 6,
            backgroundColor: '#fcfcf8',
            order: 0,
            isDisabled: false
        },
        {
            id: 7,
            backgroundColor: '#9C27B0',
            order: 0,
            isDisabled: false
        },
        {
            id: 8,
            backgroundColor: '#FF9800',
            order: 0,
            isDisabled: false
        },
        {
            id: 9,
            backgroundColor: '#9E9E9E',
            order: 0,
            isDisabled: false
        },
        {
            id: 10,
            backgroundColor: '#795548',
            order: 0,
            isDisabled: false
        },
        {
            id: 11,
            backgroundColor: '#F754E1',
            order: 0,
            isDisabled: false
        },
        {
            id: 12,
            backgroundColor: '#212121',
            order: 0,
            isDisabled: false
        },
        {
            id: 13,
            backgroundColor: 'кристалл',
            order: 0,
            isDisabled: false
        },
    ]);

    const onChangeColorBlock = async (id) => {
        setCountForActiveButton((prev) => ++prev);
        console.log(countForActiveButton, answers.length);

        let max = answers.reduce((acc, curr) => acc.order > curr.order ? acc : curr)
        let newVar = await answers.map((item) =>
            item.id === id
                ? {...item, id, isDisabled: true, order: max.order + 1}
                : item
        );
        setAnswers(newVar)

        if(countForActiveButton === answers.length) {
            setIsInputCorrect(true);
        };
    }


    const sendData = async () => {

        let answersToSend = answers.map(item => item.order);

        setTimeout(async () => {
            setIsLoading(true)

            let lang = '62c984e56bb938e21db31692';

            await getU('api/languages')
                .then((response) => {
                    const {_id} = response.data.languages.find(lang => lang.title === languageFromLocalStore)
                    lang = _id
                })

            const payload = {
                isAdult: false,
                order: answersToSend,
                language: lang,
                testId: '62f10f6955a8faabe2e88d58'
            }

            const user = TokenStorage.getUser();

            await postU(`api/tests/proficiency/${user.id}`, payload)
                .then((response) => {
                    if (response.status === 'success') {
                        const resultStatus = response.data.resultStatus;
                        console.log("success")
                        navigate('/app/test-passed', {state: {resultStatus}})
                    }
                    if (response.status === 'fail' && response.message === 'Сперва необходимо оплатить тест') {
                        navigate('/paymentSelector')
                    }
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        window.location.replace('paymentSelector')
                    } else {
                        setErrMsgVisible(true);
                        setInfoTextId('serverError')
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })

        }, 1000)

    };

    useEffect(() => {
        var found = answers.find(e => !e.isDisabled);
        if (found) {
        } else {
            setIsInputCorrect(true)
        }
    }, [answers])
    
    if (isLoading) {
        return (
            <section>
                <h1
                    style={{
                        width: "90%",
                        height: "max-content",
                        margin: "25px auto",
                        fontWeight: "bold",
                    }}
                >
                    {messages[languageFromLocalStore].expectationResult}
                </h1>
                <div className="loader">
                    <BallTriangle
                        height="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    />
                </div>
            </section>
        );
    };

    return (
        <div className="test-block" style={{minHeight: `calc(100vh - ${domSizesStore.domSizes.headerHeight}px)`}}>

            <div className="test-header">
                <div className="test-header__content content-wrapper">

                    <div className="test-header__logo">
                        <div className="logo">
                            <Logo/>
                        </div>
                    </div>

                    <div className="test-header__lang">
                        <LangSelect listPosition="bottom"/>
                    </div>

                </div>
            </div>

            <div className="test">
                <div className="content-wrapper">

                    <div className="test__descr">
                        <h2 className="test__name">
                            <FormattedMessage id="testing"/>
                        </h2>

                        <p className="test__about">
                            <FormattedMessage id="helpTextForChildTest1"/>
                            <div/>
                            <FormattedMessage id="helpTextForChildTest2"/>
                        </p>
                    </div>

                    <div className="test__wrapper">
                        <div className="answer-list">
                            {
                                answers.map((item) =>
                                    <button
                                        disabled={item.isDisabled}
                                        className={
                                            item.backgroundColor === 'кристалл'
                                                ? 'answer-item__crystal'
                                                : 'answer-item'
                                        }
                                        style={{
                                            backgroundColor: item.backgroundColor === 'кристалл'
                                                ? ''
                                                : item.backgroundColor,
                                            opacity: item.isDisabled ? 0.5 : '',
                                            border: item.isDisabled ? "2px solid rgba(108,109,109, 0.5)" : '',
                                        }}
                                        onClick={() => onChangeColorBlock(item.id)}
                                    />
                                )
                            }
                        </div>
                    </div>

                </div>
            </div>

            <div className="test-result">
                <div className="content-wrapper">

                    <div className="send-result">
                        <div className="send-result__btn">
                            <button className="site-button"
                                    disabled={!isInputCorrect}
                                    onClick={sendData}
                            >
                                <FormattedMessage id="send"/>
                            </button>
                        </div>

                        <div className="send-result__txt">
                            <p style={{color: 'red', display: errMsgVisible ? 'block' : 'none'}}>
                                <FormattedMessage id={infoTextId}/>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            {/*{modalStatus.id !== 0 &&*/}
            {/*<ModalColorPicker*/}
            {/*    modalStatus={modalStatus}*/}
            {/*    setModalStatus={setModalStatus}*/}
            {/*    onChangeColor={onChangeColor}*/}
            {/*    answers={answers}*/}
            {/*/>*/}
            {/*}*/}
        </div>
    );
};

export default ChildrenTest;
