import React, { useState } from 'react';
import InfoButton from '../InfoButton/InfoButton';
import LangSelect from '../LangSelect/LangSelect';
import Logo from './../Logo/Logo'
import { FormattedMessage } from 'react-intl';
import './SubHeader.css';

const SubHeader = () => {
    const [headerTxtVisible, setHeaderTxtVisible] = useState(false);

    return (
        <div className="sub-header">
            <div className="sub-header__top">
                <div className="sub-header__info">
                    <InfoButton clickCallback={() => setHeaderTxtVisible(!headerTxtVisible)} />
                </div>

                <div className="sub-header__lang-select">
                    <LangSelect listPosition="bottom" />
                </div>
            </div>

            <div className="sub-header__logo">
                <Logo />
            </div>
            
            <div className="sub-header__text" style={{ display: headerTxtVisible ? 'block' : 'none' }}>
                <h2>
                    <FormattedMessage id="welcome" />
                </h2>

                <p>
                    <FormattedMessage id="aboutTest" />
                </p>
            </div>
        </div>
    );
};

export default SubHeader;