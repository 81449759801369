import React, {useEffect, useState} from 'react';
import {LOCALES} from './../../languages/locales';
import AppLocalesStorage from './../../languages/AppLocalesStorage';
import './LangSelect.css';
import earthIcon from './../../images/earth.svg';
import russiaFlag from './../../images/flags/russia.svg';
import ukFlag from './../../images/flags/uk.svg';
import uaFlag from './../../images/flags/ua.svg';

const LangSelect = ({listPosition = 'top', styles}) => {
    const [currentLang] = useState(AppLocalesStorage.getLocale());
    const [langListOpened, setLangListOpened] = useState(false);
    const [languages] = useState([
        {lang: LOCALES.RUSSIAN, icon: russiaFlag},
        {lang: LOCALES.ENGLISH, icon: ukFlag},
        {lang: LOCALES.UKRAINIAN, icon: uaFlag},
    ]);

    const changeLang = (newLocale) => {
        if (newLocale !== currentLang) {
            AppLocalesStorage.setLocale(newLocale);
            window.location.reload();
        }
    };

    const pageClicked = (evt) => {
        if (!evt.target.closest('.lang-select')) {
            setLangListOpened(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', pageClicked);

        return () => document.removeEventListener('click', pageClicked);
    }, []);

    return (
            <div
                className="lang-select"
                style={{
                    boxShadow: langListOpened ? '0 0 8px rgba(0, 0, 0, 0.2)' : '',
                    backgroundColor: langListOpened ? '#fff' : '',
                    ...styles
                }}
            >
                {
                    listPosition === 'top'
                    &&
                    <div className="lang-select__list" style={{display: langListOpened ? 'block' : 'none'}}>
                        <ul>
                            {
                                languages.map((item, ind) => {
                                    return <li className="lang-select__item" key={ind}
                                               onClick={() => changeLang(item.lang)}>
                                        <div className="lang-select__item-inner">
                                            <span className="lang-select__item-lang">{item.lang.toUpperCase()}</span>
                                            <img src={item.icon} alt="flag"/>
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                }

                <div
                    className="lang-select__panel"
                    onClick={() => setLangListOpened(!langListOpened)}
                >
                    <div className="lang-select__lang">
                        {currentLang.toUpperCase()}
                    </div>

                    <div className="lang-select__icon">
                        <img src={earthIcon} alt="earth icon"/>
                    </div>
                </div>
                {
                    listPosition === 'bottom'
                    &&
                    <div className="lang-select__list" style={{display: langListOpened ? 'block' : 'none'}}>
                        <ul>
                            {
                                languages.map((item, ind) => {
                                    return <li className="lang-select__item" key={ind}
                                               onClick={() => changeLang(item.lang)}>
                                        <div className="lang-select__item-inner">
                                            <span className="lang-select__item-lang">{item.lang.toUpperCase()}</span>
                                            <img src={item.icon} alt="flag"/>
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                }
            </div>

    );
};

export default LangSelect;
