import React from 'react';

const NotFound = () => {
    return (
        <div className="not-found content-pads">
            <h2 className="site-header" style={{ textAlign: 'center' }}>
                Error: page not found
            </h2>
        </div>
    );
};

export default NotFound;
