import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import DomSizesContext from '../../contexts/DomSizes';
import Logo from '../Logo/Logo';
import './Header.css';

const Header = () => {
    let headerDomElem = useRef();
    const domSizesStore = useContext(DomSizesContext);

    useEffect(() => {
        domSizesStore.setDomSizes({
            ...domSizesStore.domSizes,
            headerHeight: headerDomElem.current.offsetHeight
        });
    }, [headerDomElem]);

    return (
        <header className="main-header" ref={headerDomElem}>
            <div className="main-header__content content-wrapper">

                <div className="main-header__logo">
                    <Link to="/sign-up-contacts" className="logo-link">
                        <div className="logo">
                            <Logo />
                        </div>
                    </Link>
                </div>
                
            </div>
        </header>
    );
};

export default Header;
