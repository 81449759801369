import React from 'react';
import {useLocation, useParams} from "react-router-dom";
import PaymentError from "../PaymentError/PaymentError";
import PaymentSuccess from "../PaymentSuccess/PaymentSuccess";

const PaymentResult = () => {

    const {search} = useLocation();
    const success = search.includes("success=true");
    const canceled = search.includes("canceled=true");

    return (
        <>
            {success &&
                <PaymentSuccess/>
            }
            {canceled &&
                <PaymentError/>
            }
        </>
    );
};

export default PaymentResult;
