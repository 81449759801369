import React, {useContext, useEffect, useState} from 'react';
import SubHeader from '../../../components/SubHeader/SubHeader';
import {Link, useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useIntl} from 'react-intl';
import DomSizesContext from '../../../contexts/DomSizes';
import './../SignUp.css';
import './SignUpName.css';
import {put, useGet, usePut} from "../../../api/request";
import TokenStorage from "../../../api/TokenStorage";

const SignUpName = () => {

    const navigate = useNavigate();
    const intl = useIntl();
    const domSizesStore = useContext(DomSizesContext);
    const putU = usePut()
    const getU = useGet();

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');

    const [nameError, setNameError] = useState();
    const [surnameError, setSurnameError] = useState();

    const validate = () => {
        let success = true;

        if (name === '') {
            setNameError('emptyError')
            success = false;
        }
        if (surname === '') {
            setSurnameError('emptyError')
            success = false;
        }

        if (success) {
            sendName();
        }
    }
    useEffect(() => {
        let user = TokenStorage.getUser();
        let name = user.name === null ? '' : user.name.toString()
        let surname = user.surname === null ? '' : user.surname.toString()

        setName(name)
        setSurname(surname)

    },[])

    const sendName = () => {
        const data = new FormData();

        data.append("name", name)
        data.append("surname", surname)

        putU('api/users/current', data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.status === 'success') {
                console.log("success")
                navigate('/app/paymentSelector');
            } else {
                console.log("error")
            }
        })
    }

    return (
        <div className="sign-up">
            <SubHeader/>

            <div className="content-wrapper"
                 style={{minHeight: `calc(100vh - ${domSizesStore.domSizes.headerHeight}px)`}}>
                <div className="sign-up__text-wrap">
                    <div className="sign-up__text">
                        <h1 className="header">
                            <FormattedMessage id="welcome"/>
                        </h1>
                        <p className="text">
                            <FormattedMessage id="aboutTest"/>
                        </p>
                    </div>
                </div>

                <div className="sign-up__form-wrap">
                    <div className="sign-up-name">
                        <form>
                            <input
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                onFocus={() => setNameError('')}
                                value={name}
                                className="site-input sign-up-name__inp"
                                placeholder={intl.formatMessage({id: 'name'})}
                            />
                            {nameError &&
                            <div className="emailError">
                                <h4>
                                    <FormattedMessage id={nameError}/>
                                </h4>
                            </div>

                            }
                            <input
                                type="text"
                                onChange={(e) => setSurname(e.target.value)}
                                onFocus={() => setSurnameError('')}
                                value={surname}
                                className="site-input sign-up-name__inp"
                                placeholder={intl.formatMessage({id: 'surname'})}
                            />
                            {surnameError &&
                            <div className="emailError">
                                <h4>
                                    <FormattedMessage id={surnameError}/>
                                </h4>
                            </div>

                            }
                            <div className="sign-up-contacts__submit">

                                <div className="site-button" onClick={validate}>
                                    <FormattedMessage id="toPayment"/>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpName;
