import React, {useState, useContext, useEffect, useRef, useMemo} from 'react';
import SubHeader from '../../../components/SubHeader/SubHeader';
import DomSizesContext from '../../../contexts/DomSizes';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import './../SignUp.css';
import './SignUpSMS.css';
import eyeIcon from './../../../images/eye.svg';
import {get, post} from "../../../api/request";
import {BallTriangle} from "react-loader-spinner";
import TokenStorage from "../../../api/TokenStorage";
import {fetchLogin, setTokens} from "../../../redux/slices/auth";
import {useDispatch} from "react-redux";
import AppLocalesStorage from "../../../languages/AppLocalesStorage";
import getEnvVars from "../../../api/environment";

const SignUpSMS = () => {

    const {apiUrl} = getEnvVars();

    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [smsCodeHidden, setSmsCodeHidden] = useState(true);
    const [smsAgainAvailable, setSmsAgainAvailable] = useState(false);

    const intl = useIntl();
    const domSizesStore = useContext(DomSizesContext);

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');

    //timer
    let minutes = 0;
    let seconds = 30
    const [over, setOver] = React.useState(false);
    const [[m, s], setTime] = React.useState([minutes, seconds]);


    console.log(`${m} / ${s}`)

    const tick = () => {
        if (over) return;

        if (m === 0 && s === 0) {
            setOver(true);
        } else if (s === 0) {
            setTime([m - 1, 59]);
        } else {
            setTime([m, s - 1]);
        }
    };
    const reset = () => {
        setTime([parseInt(minutes), parseInt(seconds)]);
        setOver(false);
    };

    useEffect(() => {

        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);

    }, [m,s, over]);

    useEffect(() => {
        if (over) {
            setSmsAgainAvailable(true)
        }
    }, [over])

    useEffect(() => {
        const hostUrl = window.location.origin.toString()
        if (!location.state) {
            window.location.href = `${hostUrl}/sign-up-contacts`
        }
    }, [])


    const validate = () => {
        let successValidate = true;

        if (code.trim() === '') {
            setCodeError('emptyError');
            successValidate = false
        }

        if (code.length !== 6) {
            setCodeError('minLengthInput');
            successValidate = false
        }

        if (successValidate) {
            sendCode();
        }

    }

    const sendCode = () => {
        setIsLoaded(true)
        let qs = require('qs');
        let data = qs.stringify({
            code
        });

        fetchLogin(dispatch, {
            data,
            onSuccess: async ({tokens, user}) => {

                await TokenStorage.setAccessToken(tokens.access)
                await TokenStorage.setRefreshToken(tokens.refresh)
                await TokenStorage.setTokenExpire(tokens.expiresIn)
                await TokenStorage.setUser(user)
                await TokenStorage.setTokenReceived(Date.now() / 1000);

                await setIsLoaded(false)
                await navigate('/app/sign-up-name');

            },
            onFailed: (e) => {
                console.log(e.response.data.message)

                if (e.response.data.message) {
                    setCodeError(e.response.data.message)
                } else {
                    setCodeError("incorrectError")
                }
                setIsLoaded(false)

            }
        })
    }

    const sendSmsAgain = async () => {
        await setSmsAgainAvailable(false)
        await reset();

        const languageFromLocalStore = AppLocalesStorage.getLocale();
        let language = '62c984e56bb938e21db31692';

        await get('api/languages')
            .then((response) => {
                const {_id} = response.data.languages.find(lang => lang.title === languageFromLocalStore)
                language = _id
            })

        let qs = await require('qs');
        let data = await qs.stringify({
            email: location?.state?.email,
            phoneNumber: `+${location?.state?.phoneNumber}`,
            language
        });
        await post('api/auth', data, {
            headers: {'content-type': 'application/x-www-form-urlencoded'}
        })
    }

    return (
        <div className="sign-up">
            <SubHeader/>

            <div className="content-wrapper"
                 style={{minHeight: `calc(100vh - ${domSizesStore.domSizes.headerHeight}px)`}}>
                <div className="sign-up__text-wrap">

                    <div className="sign-up__text">
                        <h1 className="header">
                            <FormattedMessage id="welcome"/>
                        </h1>
                        <p className="text">
                            <FormattedMessage id="aboutTest"/>
                        </p>
                    </div>

                </div>
                {isLoaded
                    ? <div className="loader">
                        <BallTriangle
                            height="100"
                            width="100"
                            color='grey'
                            ariaLabel='loading'
                        />
                    </div>
                    : <div className="sign-up__form-wrap">

                        <div className="sign-up-sms">
                            <div className="sign-up-sms__text">
                                <p>
                                    <FormattedMessage id="smsSentFirstPart"/>
                                    {location?.state?.email}
                                    <FormattedMessage id="smsSentSecondPart"/>
                                </p>
                            </div>
                            <form>
                                <div className="sign-up-sms__inp">
                                    <input
                                        onFocus={() => {
                                            setCodeError('')
                                        }}
                                        type={smsCodeHidden ? 'password' : 'number'}
                                        onChange={(e) => setCode(e.target.value)}
                                        value={code}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        className="site-input"
                                        placeholder={intl.formatMessage({id: 'smsCode'})}
                                    />
                                    {codeError &&
                                    <div className="emailError">
                                        <h4>
                                            {codeError === "incorrectError" || codeError === "emptyError" || codeError === "minLengthInput"
                                                ? <FormattedMessage id={codeError}/>
                                                : <p>{codeError}</p>
                                            }
                                        </h4>
                                    </div>
                                    }
                                    <span className="sign-up-sms__icon"
                                          onClick={() => setSmsCodeHidden(!smsCodeHidden)}>
                                    <img src={eyeIcon} alt="eye icon"/>
                                    <div className="eye-line" style={{display: smsCodeHidden ? 'block' : 'none'}}></div>
                                </span>
                                </div>

                                <div className="sign-up-contacts__submit">
                                    <div className="site-button" onClick={validate}>
                                        <FormattedMessage id="continue"/>
                                    </div>
                                </div>

                                <div className="sign-up-contacts__submit">
                                    <button onClick={sendSmsAgain}
                                            className="site-button"
                                            disabled={!smsAgainAvailable}>
                                        <FormattedMessage id="sendAgain"/>
                                    </button>
                                    {!over &&
                                    <div className="sendAgain">
                                        <FormattedMessage id="sendAgainSms"/>
                                        {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
                                    </div>
                                    }
                                </div>
                            </form>
                        </div>

                    </div>

                }
            </div>
        </div>
    );
};

export default SignUpSMS;
