import axios from 'axios';
import getEnvVars from './environment';
import TokenStorage from "./TokenStorage";
const { apiUrl } = getEnvVars();

let transport;


export const configureTransport = (token = null) => {

    const options = token || TokenStorage.getAccessToken() ?
        {
            baseURL: apiUrl,
            headers: {
                Authorization: `Bearer ${token}`,
                access_token: token,
            },
        }
        : { baseURL: apiUrl };
    transport = axios.create(options);
    return transport;
};

export const getTransport = () => transport || configureTransport();
