import React, {useEffect, useState} from 'react';
import AppLocalesStorage from './../../languages/AppLocalesStorage';
import './HelpButton.css';
import helpIcon from '../../images/helpPanel/helpIcon.svg';
import closeHelpPanel from '../../images/helpPanel/helpCloseIcon.svg';
import helpInpEmail from '../../images/helpPanel/helpInpEmail.svg';
import helpInpMan from '../../images/helpPanel/helpInpMan.svg';
import russiaFlag from './../../images/flags/russia.svg';
import ukFlag from './../../images/flags/uk.svg';
import {FormattedMessage} from "react-intl";
import mailIcon from "../../images/mail.svg";
import phoneIcon from "../../images/phone.svg";
import {useIntl} from 'react-intl';
import {get, post} from "../../api/request";
import {BallTriangle} from "react-loader-spinner";
import {Box} from '@material-ui/core';


const MAX_COUNT = 5;

const HelpButton = ({styles}) => {

    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [langListOpened, setLangListOpened] = useState(false);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const [textArea, setTextArea] = useState('');
    const [textAreaError, setTextAreaError] = useState('');

    const [selectedName, setSelectedName] = useState(false)
    const [selectedEmail, setSelectedEmail] = useState(false)

    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);


    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 1400);
    };

    const reset = () => {
        setName('');
        setEmail('');
        setTextArea('');
        setUploadedFiles([]);
    }

    const validate = () => {
        let allRight = true;

        if (name.trim() === '') {
            setNameError('emptyError')
            allRight = false
        }

        if (email.trim() === '') {
            setEmailError('emptyError')
            allRight = false
        } else if (!email.match(/\S+@\S+\.\S+/)) {
            setEmailError('incorrectError')
            allRight = false
        }

        if (textArea === '') {
            setTextAreaError('emptyError')
            allRight = false
        }

        if (allRight) {
            sendEmail();
            reset();
        }
    }



    const sendEmail = async () => {
        setIsLoading(true)

        setTimeout(() => {
            setIsLoading(false)
        }, 1200)


        const data = new FormData();

        data.append("name", name)
        data.append("email", email)
        data.append("message", textArea)
        uploadedFiles.forEach((file) =>{
            data.append("attachments", file)
        })

        await post('api/feedbacks', data)
            .then((response) => {
                if (response.status === 'success') {
                    setIsLoading(false)
                    showAlert("success", "success")
                }
            }).catch((e) => {
                if (e.response.status === 400) {
                    setIsLoading(false)
                    showAlert("error", "error")
                }
            })
    }


    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)

    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    const deleteElement = async (index) => {
        let newVar = await uploadedFiles.filter((item, i) => i !== index);
        await setUploadedFiles(newVar)
    }

    // useEffect(() => {
    //
    // }, [uploadedFiles])

    return (
        <div
            className="help-button"
        >
            {!langListOpened
                ? (
                    <div
                        className="help-panel"
                        onClick={() => setLangListOpened(!langListOpened)}
                    >
                        <div className="help-panel__icon">
                            <img src={helpIcon} alt="earth icon"/>
                        </div>
                        <div className="help-panel__text">
                            <FormattedMessage id="helpButton"/>
                        </div>
                    </div>
                )
                : (
                    <div className="help-panel__opened">
                        <div className="help-panel__top">
                            <div className="help-panel__topText">
                                <FormattedMessage id="leaveTheMessage"/>
                            </div>
                            <div
                                className="help-panel__closeIcon"
                                onClick={() => setLangListOpened(!langListOpened)}>
                                <img src={closeHelpPanel} alt="closeIcon"/>
                            </div>
                        </div>

                        {isLoading
                            ? (
                                <div className="loader">
                                    <BallTriangle
                                        height="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    />
                                </div>
                            )
                            : (
                                <form className="help-panel__form">

                                    <div className="help-panel__inp">

                                        <div className="help-panel__title">
                                            <FormattedMessage id="helpName"/>
                                        </div>

                                        <div
                                            className={`help-panel__inpInside ${selectedName ? "help-panel__inpInsideSelected" : ""}`}>

                                            <div className="help-panel_ipnIcon">
                                                <img src={helpInpMan} alt="mail icon"/>
                                            </div>

                                            <input
                                                onChange={(e) => setName(e.target.value)}
                                                onFocus={() => {
                                                    setNameError('')
                                                    setSelectedName(true)
                                                }}
                                                onBlur={() => {
                                                    setSelectedName(false)
                                                }}
                                                value={name}
                                                type="text"
                                                className="help-inp"
                                                placeholder={intl.formatMessage({id: 'name'})}/>

                                        </div>
                                        {nameError &&
                                        <div className="emailError">
                                            <h4>
                                                <FormattedMessage id={nameError}/>
                                            </h4>
                                        </div>
                                        }
                                    </div>

                                    <div className="help-panel__inp">

                                        <div className="help-panel__title">
                                            <FormattedMessage id="helpEmail"/>
                                        </div>

                                        <div
                                            className={`help-panel__inpInside ${selectedEmail ? "help-panel__inpInsideSelected" : ""}`}>

                                            <div className="help-panel_ipnIcon">
                                                <img src={helpInpEmail} alt="mail icon"/>
                                            </div>

                                            <input
                                                onChange={(e) => setEmail(e.target.value)}
                                                onFocus={() => {
                                                    setEmailError('')
                                                    setSelectedEmail(true)
                                                }}
                                                onBlur={() => {
                                                    setSelectedEmail(false)
                                                }}
                                                value={email}
                                                type="text"
                                                className="help-inp"
                                                placeholder="Email"/>

                                        </div>

                                        {emailError &&
                                        <div className="emailError">
                                            <h4>
                                                <FormattedMessage id={emailError}/>
                                            </h4>
                                        </div>
                                        }
                                    </div>

                                    <div className="help-panel__texArea">

                                        <div className="help-panel__title">
                                            <FormattedMessage id="howWeCanHelp"/>
                                        </div>

                                        <div className="help-panel__texAreaInside">
                                    <textarea
                                        value={textArea}
                                        onFocus={() => setTextAreaError('')}
                                        onChange={(e) => setTextArea(e.target.value)}
                                    />

                                        </div>
                                        {textAreaError &&
                                        <div className="emailError">
                                            <h4>
                                                <FormattedMessage id={textAreaError}/>
                                            </h4>
                                        </div>
                                        }
                                    </div>

                                    <div className="help-panel__files">
                                        <div className="filesName">
                                            <input
                                                type="file"
                                                multiple
                                                disabled={fileLimit}
                                                style={{display: 'none'}}
                                                onChange={handleFileEvent}
                                                ref={hiddenFileInput}/>
                                            {uploadedFiles?.length > 0
                                                ? (

                                                    <div>
                                                        {uploadedFiles?.map((file, index) => (
                                                            <div className="imageWrapper" key={index}>
                                                                <div className="inline">
                                                                    <div className="files">
                                                                        {file.name + " "}
                                                                    </div>
                                                                    <div className="imageIcon"
                                                                         onClick={() => deleteElement(index)}>
                                                                        <img src={closeHelpPanel} alt="closeIcon"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                )
                                                : <FormattedMessage id="helpFiles"/>
                                            }
                                        </div>
                                        <div className="addFile"
                                             onClick={handleClick}>
                                            <FormattedMessage id="helpReview"/>
                                        </div>
                                    </div>

                                    {/*<div severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>*/}
                                    <div style={{ display: alert.isVisible && alert.type === 'error' ? 'flex' : 'none'}} className="alert-error">
                                        {alert.txt}
                                    </div>
                                    <div  style={{ display: alert.isVisible && alert.type === 'success' ? 'flex' : 'none'}} className="alert-success">
                                        {alert.txt}
                                    </div>

                                    <div className="sign-up-contacts__submit">

                                        <div className="site-button" onClick={validate}>
                                            <FormattedMessage id="helpSendReq"/>
                                        </div>

                                    </div>


                                </form>
                            )

                        }

                    </div>
                )
            }

        </div>

    );
};

export default HelpButton;
