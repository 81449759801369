import { LOCALES } from "./locales";

export default class AppLocalesStorage {
    static getLocale() {
        const locale = localStorage.getItem('locale');
        return locale ? locale : 'ru' ;
    }

    static setLocale(newLocale) {
        localStorage.setItem('locale', newLocale);
    }

    static setLang(newLang) {
        localStorage.setItem('language', newLang);
    }

    static getLang() {
        return localStorage.getItem('language');
    }

    static getDefaultLocale() {
        return LOCALES.RUSSIAN;
    }

    static setEverything(key, value) {
        localStorage.setItem(key, value)
    }

    static getEverything(key) {
        return localStorage.getItem(key)
    }
}
