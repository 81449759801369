import React from 'react';
import {FormattedMessage} from "react-intl";

const CantPassTest = () => {
    return (
        <div className="test-passed" style={{
            textAlign: 'center',
            fontWeight: 700,
            padding: '120px 0',
            fontSize: '28px',
            minHeight: 500,
        }}

        >
            <FormattedMessage id="cantPassTest" style={{fontSize: '24px'}}/>
        </div>
    );
};

export default CantPassTest;
