import { LOCALES } from "./locales"

const messages = {
    [LOCALES.RUSSIAN]: {
        welcome: 'Здесь Вы пройдете тестирование для определения ваших талантов и получения рекомендаций по выбору профессии.',
        aboutTest: 'Тестирование по методу «Моя карта» является комбинацией проективных методик, смысловых значений архетипов, и использования цветовых ассоциаций у этнических народов.'
                   +
                   'Тестирование обладает высокой точностью, и при этом максимально простое – делается в один шаг. ',
        phone: 'Телефон',
        helpTextForChildTest1: 'Перед вами цветовые карточки. Нажмите на каждую из них в произвольном порядке, не задумываясь, ориентируйтесь только на цвет на карточке. Следует нажать на все карточки.',
        helpTextForChildTest2: 'Если Вы – родитель, а Ваш ребенок маленький – помогите ребенку, объясните задание, помогите ему нажать на экран/мышку, но не следует выбирать за него. Дайте ребенку время, не поторапливайте, если он долго выбирает.',
        helpButton: 'Помощь',
        helpName: 'Ваше имя',
        helpEmail: 'Почта',
        howWeCanHelp: 'Чем мы Вам можем помочь?',
        helpFiles: 'Добавить файлы',
        helpSendReq: 'Отправить заявку',
        helpReview: 'Обзор',
        leaveTheMessage: 'Оставьте нам сообщение',
        registration: 'Регистрация',
        forgotPassword: 'Забыли пароль?',
        continue: 'Продолжить',
        sendAgain: 'Отправить ещё раз',
        smsCode: 'Код',
        smsSentFirstPart: "На Вашу почту: ",
        smsSentSecondPart: " отправлен код подтверждения. Код действует 3 минуты.",
        name: 'Имя',
        surname: 'Фамилия',
        requisites: 'Укажите реквизиты для оплаты',
        paymentPay: 'Оплата',
        minLengthInput: 'Длина кода должна составлять 6 символов',
        choosePaymentMethod: 'Выберите удобный способ оплаты',
        goToPayment: 'Перейти к оплате',
        toPayment: 'К оплате',
        somthWentWrong: 'Что-то пошло не так',
        tryAgainLater: 'Пожалуйста, повторите попытку позже',
        paymentSuccess: 'Вы успешно оплатили',
        goToKidTest: 'Перейти к детскому тесту',
        goToAdultTest: 'Перейти к взрослому тесту',
        throwOff: 'Сбросить',
        incorrectError: 'Вы ввели некорректные данные',
        numberLenghtError: 'Поле должно быть не менее 10 символов',
        sendAgainSms: 'Отправить еще раз через: ',
        emptyError: 'Поле должно быть заполнено',
        paymentByCard: 'Оплата картой',
        payByPayPall: 'Оплата PayPal',
        sumToPay: 'Сумма к оплате',
        cardNumber: 'Номер карты',
        pay: 'Оплатить',
        cantPassTest: 'Тест уже пройден',
        tyForPay: "Спасибо! Мы получили Ваш платеж.",
        serverError: 'Что-то пошло не так. Повторите попытку позже',
        repeat: 'Повторить',
        paymentWasNotMade: "Извините платеж не был совершен.",
        tryAgain: "Попробуйте оплатить еще раз или используйте другую карту.",
        paymentError: 'Ошибка оплаты',
        addCard: 'Добавить карту',
        variant: 'Вариант',
        send: 'Отправить',
        thankForTest: 'Благодарим за пройденный тест! Результат вы получите на указанную email почту',
        thankForTestMod: "Благодарим за пройденный тест! Тест находится в режиме модерации, в ближайшее время мы отправим Вам результат на указанную почту.",
        answerContainsRepeats: 'Ошибка! Числа не должны повторяться',
        typeCorrectNumb: 'Введите значения от 1 до 13',
        testing: 'Тестирование',
        pressAllButtonsNotThink: 'Нажми на цветовые карточки в произвольном порядке, не задумываясь. Ориентируйся только на цвет, который хочешь выбрать, независимо от цветового предпочтения. Нужно нажать на все карточки.',
        typeAllNubmersNotThink: 'Впишите в ячейки все числа от 1 до 13 в произвольном порядке, не задумываясь. Перед записью расслабьтесь на несколько секунд, вдох-выдох, и сделайте интуитивный выбор, следуя числам, приходящим в голову. Убедитесь, что вы не дублируете числа.',
        expectationResult: 'Не закрывайте вкладку, результаты теста будут готовы в течении 5 минут. Если возникли проблемы с получением результата, пройденного теста, обратитесь в техническую поддержку.',
    },
    [LOCALES.ENGLISH]: {
        welcome: 'Here you will be tested to determine your talents and get recommendations for choosing a profession.',
        aboutTest: 'Testing according to the "My Map" method is a combination of projective methods, the semantic meanings of archetypes, and the use of colour associations among ethnic peoples.'
                   +
                   'Testing is highly accurate and at the same time as simple as possible - it is done in one step.',
        phone: 'Phone',
        helpTextForChildTest1: 'Here are the color cards. Click on each of them in any order, without hesitation, focus only on the color on the card. You should click on all the cards.',
        helpTextForChildTest2: 'If you are a parent and your child is small – help the child, explain the task, help him to click on the screen / mouse, but you should not choose for him. Give the child time, do not hurry up if he chooses for a long time.',
        helpButton: 'Help',
        leaveTheMessage: 'Leave us a message',
        helpName: 'Your name',
        helpEmail: 'Email',
        howWeCanHelp: 'How can we help you?',
        helpFiles: 'Add files',
        helpReview: 'Review',
        helpSendReq: 'Send a request',
        registration: 'Registration',
        forgotPassword: 'Forgot password?',
        continue: 'Continue',
        sendAgain: 'Send again',
        smsCode: 'Сode',
        smsSentFirstPart: "A confirmation code has been sent to your email: ",
        smsSentSecondPart: " The code is valid for 3 minutes.",
        smsSent: 'SMS with confirming code had been sent to your phone +*** *** ** 55. SMS-code. This code is valid for 3 minutes.',
        name: 'Name',
        surname: 'Surname',
        requisites: 'Payment details',
        choosePaymentMethod: 'Choose a convenient payment method',
        goToPayment: 'Go to payment',
        paymentByCard: 'Payment by card',
        payByPayPall: 'Payment by PayPal',
        emptyError: 'The field must be filled in',
        minLengthInput: 'Code length must be 6 characters long',
        incorrectError: 'You have entered incorrect data',
        numberLenghtError: 'The field must be at least 10 characters long',
        paymentPay: 'Payment',
        toPayment: 'To payment',
        serverError: 'Something went wrong. Try again later',
        somthWentWrong: 'Something went wrong',
        tryAgainLater: 'Please try again later',
        cantPassTest: 'The test has already been passed',
        sumToPay: 'Sum to pay',
        cardNumber: 'Card Number',
        sendAgainSms: 'Send again via: ',
        pay: 'Pay',
        tyForPay: "Thanks! We have received your payment.",
        repeat: 'Try Again',
        paymentSuccess: 'You have successfully paid',
        goToKidTest: 'Go to the children\'s test',
        goToAdultTest: 'Go to the adult test',
        throwOff: 'Throw off',
        paymentError: 'Payment error',
        paymentWasNotMade: "Sorry the payment was not made.",
        tryAgain: "Try to pay again or use another card.",
        addCard: 'Add a card',
        variant: 'Variant',
        send: 'Send',
        thankForTest: 'Thank you for passing the test! Result will be sent to the specified email',
        thankForTestMod: "Thank you for passing the test! The test is in moderation mode, in the near future we will send you the result to the specified email.",
        answerContainsRepeats: 'Error! Numbers should not repeat',
        typeCorrectNumb: 'Type values from 1 to 13',
        testing: 'Testing',
        pressAllButtonsNotThink: 'Click on the color cards in random order without thinking. Focus only on the color you want to choose, regardless of color preference. You need to click on all the cards.',
        typeAllNubmersNotThink: 'Write down all the numbers from 1 to 13 in a random order without thinking. Before taping relax for a few seconds, breathe in and out, and make an intuitive choice, following the numbers coming to your mind. Make sure you don\'t duplicate any numbers.',
        expectationResult: 'Do not close the tab, the test results will be ready within 5 minutes. If you have problems getting the result of the completed test, contact technical support.',
    },
    [LOCALES.UKRAINIAN]: {
        welcome: 'Тут ви пройдете тестування для визначення Ваших талантів і отримання рекомендацій щодо вибору професії',
        aboutTest: 'Тестування за методом "Моя карта" є комбінацією проективних методик, смислових значень архетипів, і використання колірних асоціацій у етнічних народів.'
                   +
                   'Тестування має високу точність, і при цьому максимально просте – робиться в один крок.',
        phone: 'Телефон',
        helpTextForChildTest1: 'Перед вами кольорові картки. Натисніть на кожну з них в довільному порядку, не замислюючись, орієнтуйтеся тільки на колір на картці. Слід натиснути на всі картки.',
        helpTextForChildTest2: 'Якщо ви – батько, а Ваша дитина маленька-допоможіть дитині, поясніть завдання, допоможіть йому натиснути на екран/мишку, але не слід вибирати за нього. Дайте дитині час, не квапте, якщо він довго вибирає.',
        helpButton:'допомога',
        helpName:'Ваше ім`я',
        helpEmail:'пошта',
        howWeCanHelp: 'чим ми можемо Вам допомогти?',
        helpFiles:'Додати файли',
        helpSendReq: 'Надіслати заявку',
        helpReview:'огляд',
        leaveTheMessage: 'залиште нам повідомлення',
        registration: 'реєстрація',
        forgotPassword: 'Забули пароль?',
        continue: 'Продовжити',
        sendAgain: 'відправити ще раз',
        smsCode:'Код',
        smsSentFirstPart: "на вашу пошту:",
        smsSentSecondPart: "надіслано код підтвердження. Код діє 3 хвилини.",
        name:'Ім`я',
        surname:'Прізвище',
        requisites: 'вкажіть реквізити для оплати',
        paymentPay:'Оплата',
        minLengthInput :' Довжина коду повинна становити 6 символів',
        choosePaymentMethod: 'виберіть зручний спосіб оплати',
        goToPayment: 'Перейти до оплати',
        toPayment :' до оплати',
        somthWentWrong: 'щось пішло не так',
        tryAgainLater: 'будь ласка, спробуйте пізніше',
        paymentSuccess :' ви успішно оплатили',
        goToKidTest: 'Перейти до дитячого тесту',
        goToAdultTest: 'Перейти до дорослого тесту',
        throwOff:'Скинути',
        incorrectError :' ви ввели неправильні дані',
        numberLenghtError: 'Поле має бути не менше 10 символів',
        sendAgainSms: 'надіслати ще раз через: ',
        emptyError: 'Поле має бути заповнене',
        paymentByCard: 'Оплата карткою',
        payByPayPall: 'Оплата PayPal',
        sumToPay: 'Сума до оплати',
        cardNumber:'номер картки',
        pay: 'оплатити',
        cantPassTest: 'Тест вже пройшов',
        tyForPay: "Дякую! Ми отримали ваш платіж.",
        serverError: 'щось пішло не так. Повторіть спробу пізніше',
        repeat:'повторити',
        paymentWasNotMade: "Вибачте платіж не був здійснений.",
        tryAgain: "спробуйте оплатити ще раз або скористайтеся іншою карткою.",
        paymentError:'помилка оплати',
        addCard:'Додати карту',
        variant:'варіант',
        send: 'відправити',
        thankForTest: 'Дякуємо за пройдений тест! Результат ви отримаєте на вказану email пошту',
        thankForTestMod: "Дякуємо за пройдений тест! Тест знаходиться в режимі модерації, найближчим часом ми відправимо вам результат на вказану пошту.",
        answerContainsRepeats: 'помилка! Числа не повинні повторюватися',
        typeCorrectNumb: 'введіть значення від 1 до 13',
        testing: 'тестування',
        pressAllButtonsNotThink: 'натисни на колірні картки в довільному порядку, не замислюючись. Орієнтуйся тільки на колір, який хочеш вибрати, незалежно від колірного переваги. Потрібно натиснути на всі картки.',
        typeAllNubmersNotThink: 'введіть у клітинки всі числа від 1 до 13 у довільному порядку, не замислюючись. Перед записом розслабтеся на кілька секунд, вдих-видих, і зробіть інтуїтивний вибір, слідуючи числам, що приходять в голову. Переконайтеся, що ви не дублюєте цифри.',
        expectationResult: 'Не закривайте вкладку, результати тесту будуть готові протягом 5 хвилин. Якщо у вас виникли проблеми з отриманням результату завершеного тесту, зверніться в службу технічної підтримки.',
    },
};

export default messages;
