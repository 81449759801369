import {createSlice} from '@reduxjs/toolkit';
import {configureTransport} from "../../api/transport";
import {post, put} from "../../api/request";
import TokenStorage from "../../api/TokenStorage";

const initialState = {
    accessToken: '',
    refreshToken: '',
    tokenExpire: '',
    user: {},
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokens: (state, action) => {
            configureTransport(action.payload.accessToken);
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.tokenExpire = action.payload.tokenExpire;
            state.user = action.payload.user
        },
        setUser: (state, action) => {
            state.user = action.payload || {};
        },
    },
});

export const fetchLogin = (dispatch, {
    data, onSuccess = () => {
    }, onFailed = () => {
    },
}) => {
    post('api/auth/verification', data, {
        headers: {'content-type': 'application/x-www-form-urlencoded'}
    })
        .then((response) => {
            if (response.status === 'success') {
                dispatch(setTokens({
                    accessToken: response.data.tokens.access,
                    refreshToken: response.data.tokens.refresh,
                    expiresIn: response.data.tokens.expiresIn,
                    user: response.user,
                }));
                onSuccess(response.data);
            } else {
                onFailed()
            }
        })
        .catch((error) => {
            onFailed(error);
        })

};

export const logout = (dispatch) => {
    dispatch(setTokens({
        accessToken: '',
        refreshToken: '',
        user: {},
        isAuth: false
    }));
};

export const {setTokens, setRememberMe, setLoginData, setUser} = authSlice.actions;

export default authSlice.reducer;
