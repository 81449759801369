import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import LangSelect from '../components/LangSelect/LangSelect';
import HelpButton from "../components/Help/HelpButton";

const MainLayout = () => {
    return (
        <main>
            <Header />
            <Outlet />
            <div className="main-lang-select" style={{ position: 'fixed', bottom: '20px', right: '35px' }}>
                <LangSelect styles={{ backgroundColor: '#fff' }} />
            </div>
            <div className="main-help__panel" style={{ position: 'fixed', bottom: '20px', right: '165px' }}>
                <HelpButton styles={{ backgroundColor: '#fff' }} />
            </div>
        </main>
    );
};

export default MainLayout;
