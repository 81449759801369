import React, {useContext, useEffect, useRef, useState} from 'react';
import SubHeader from '../../../components/SubHeader/SubHeader';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useIntl} from 'react-intl';
import DomSizesContext from '../../../contexts/DomSizes';
import './../SignUp.css';
import './SignUpContacts.css';
import mailIcon from './../../../images/mail.svg';
import phoneIcon from './../../../images/phone.svg';
import {post, get} from "../../../api/request";
import {Audio, BallTriangle} from "react-loader-spinner";
import TokenStorage from "../../../api/TokenStorage";
import AppLocalesStorage from "../../../languages/AppLocalesStorage";

const SignUpContacts = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const intl = useIntl();
    const domSizesStore = useContext(DomSizesContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [mobilePhone, setMobilePhone] = useState('');
    const [mobilePhoneToSend, setMobilePhoneToSend] = useState('');
    const [mobilePhoneError, setMobilePhoneError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [serverError, setServerError] = useState(false)

    const validate = () => {
        let allRight = true;

        if (email.trim() === '') {
            setEmailError('emptyError')
            allRight = false
        } else if (!email.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
            setEmailError('incorrectError')
            allRight = false
        }
        if (mobilePhoneToSend.trim() === '') {
            setMobilePhoneError('emptyError')
            allRight = false

        } else if (mobilePhoneToSend.length < 10) {
            setMobilePhoneError('numberLenghtError')
            allRight = false
        } else if (!Number.isInteger(Number(mobilePhoneToSend))) {
            setMobilePhoneError('incorrectError')
            allRight = false
        }

        if (allRight) {
            sendEmail();
        }
    }


    const sendEmail = async () => {
        const languageFromLocalStore = AppLocalesStorage.getLocale();

        setIsLoaded(true)

        let language = '62c984e56bb938e21db31692';

        await get('api/languages')
            .then((response) => {
                const {_id} = response.data.languages.find(lang => lang.title === languageFromLocalStore)
                language = _id
            })

        let qs = await require('qs');
        let data = await qs.stringify({
            email,
            phoneNumber: `+${mobilePhoneToSend}`,
            language
        });
        console.log("language: ", AppLocalesStorage.getLang());
        console.log({
            email,
            phoneNumber: `+${mobilePhoneToSend}`,
            language
        })

        await post('api/auth', data, {
            headers: {'content-type': 'application/x-www-form-urlencoded'}
        })
            .then((response) => {
                setIsLoaded(false)
                if (response.status === 'success') {
                    console.log(email)
                    console.log(mobilePhone)
                    navigate('/sign-up-sms', {state: {email: email, phoneNumber: mobilePhone}});
                }
            }).catch((e) => {
                if (e.response.status === 400) {
                    setIsLoaded(false)
                    setEmailError('incorrectError')
                    setMobilePhoneError('incorrectError')
                }
            }).finally(() => {
                AppLocalesStorage.setLang(language)
                setIsLoaded(false)
            })
    }


    const inputCard = useRef();

    const handleChange = () => {
        const cardValue = inputCard.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,0})(\d{0,6})(\d{0,3})(\d{0,3})(\d{0,3})/);
        inputCard.current.value = !cardValue[2]
            ? cardValue[1]
            : `+${cardValue[1]}${cardValue[2]}${`${cardValue[3] ? `${cardValue[3]}` : ''}`}${`${cardValue[4] ? `${cardValue[4]}` : ''}`}${`${cardValue[5] ? `${cardValue[5]}` : ''}`}`;

        const numbers = inputCard.current.value.replace(/(\D)/g, '');
        setMobilePhoneToSend(numbers)
        setMobilePhone(inputCard.current.value);
    };

    useEffect(() => {
        handleChange();
    }, [mobilePhone]);

    return (
        <div className="sign-up">
            <SubHeader/>
            <div className="content-wrapper"
                 style={{minHeight: `calc(100vh - ${domSizesStore.domSizes.headerHeight}px)`}}>
                <div className="sign-up__text-wrap">

                    <div className="sign-up__text">
                        <h1 className="header">
                            <FormattedMessage id="welcome"/>
                        </h1>
                        <p className="text">
                            <FormattedMessage id="aboutTest"/>
                        </p>
                    </div>

                </div>

                {isLoaded
                    ? <div className="loader">
                        <BallTriangle
                            height="100"
                            width="100"
                            color='grey'
                            ariaLabel='loading'
                        />
                    </div>
                    : <div className="sign-up__form-wrap">

                        <div className="sign-up-contacts">
                            <form>
                                <div className="sign-up-contacts__inp  sign-up-contacts__inp--email">
                                    <input
                                        onChange={(e) => setEmail(e.target.value)}
                                        onFocus={() => setEmailError('')}
                                        value={email}
                                        type="text"
                                        className="site-input"
                                        placeholder="Email"/>
                                    {emailError &&
                                    <div className="emailError">
                                        <h4>
                                            <FormattedMessage id={emailError}/>
                                        </h4>
                                    </div>
                                    }
                                    <img src={mailIcon} className="sign-up-contacts__icon" alt="mail icon"/>
                                </div>

                                <div className="sign-up-contacts__inp">
                                    <input
                                        ref={inputCard}
                                        onChange={handleChange}
                                        onFocus={() => setMobilePhoneError('')}
                                        value={mobilePhone}
                                        type="text"
                                        className="site-input"
                                        placeholder={intl.formatMessage({id: 'phone'})}/>
                                    {mobilePhoneError &&
                                    <div className="emailError">
                                        <h4>
                                            <FormattedMessage id={mobilePhoneError}/>
                                        </h4>
                                    </div>
                                    }
                                    {/*{!mobilePhoneError &&*/}
                                    {/*<div className="emailHelper">*/}
                                    {/*    <h4>*/}
                                    {/*        (***)-***-**-***/}
                                    {/*    </h4>*/}
                                    {/*</div>*/}
                                    {/*}*/}
                                    <img src={phoneIcon} className="sign-up-contacts__icon" alt="mail icon"/>
                                </div>

                                <div className="sign-up-contacts__submit">

                                    <div className="site-button" onClick={validate}>
                                        <FormattedMessage id="registration"/>
                                    </div>

                                </div>
                                {serverError &&
                                <div>
                                    <div className="serverError">
                                        <h4>
                                            <FormattedMessage id="somthWentWrong"/>
                                        </h4>
                                        <h4>
                                            <FormattedMessage id="tryAgainLater"/>
                                        </h4>
                                    </div>
                                </div>
                                }


                                {/*<div className="sign-up-contacts__forgot">*/}
                                {/*    <a href="#">*/}
                                {/*        <FormattedMessage id="forgotPassword" />*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                            </form>
                        </div>
                    </div>

                }

            </div>
        </div>
    );
};

export default SignUpContacts;
