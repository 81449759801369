import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    categoryId: 0,
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setCategoryId: (state, action) => {
            state.categoryId = action.payload;
        },

    },
})

export const {setCategoryId} = paymentSlice.actions

export default paymentSlice.reducer
