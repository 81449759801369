import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation} from "react-router-dom";

const TestPassed = () => {
    const location = useLocation();

    console.log(location?.state?.resultStatus)
    return (
        <div className="test-passed" style={{
            textAlign: 'center',
            fontWeight: 700,
            padding: '120px 60px',
            fontSize: '28px',
            minHeight: 500,

        }}

        >
            {
                location?.state?.resultStatus === "processed"
                    ? <FormattedMessage id="thankForTest" style={{fontSize: '24px'}}/>
                    : <FormattedMessage id="thankForTestMod" style={{fontSize: '24px'}}/>
            }

        </div>
    );
};

export default TestPassed;
