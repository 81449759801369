import { configureStore } from '@reduxjs/toolkit'
import payment from "./slices/payment";
import auth from "./slices/auth";

export const store = configureStore({
    reducer: {
        payment,
        auth
    },
})

