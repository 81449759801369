import React, {useState, useContext, useEffect} from 'react';
import DomSizesContext from '../../../contexts/DomSizes';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import LangSelect from '../../../components/LangSelect/LangSelect';
import InputMask from 'react-input-mask';
import Logo from '../../../components/Logo/Logo';
import './AdultTest.css';
import {useGet, usePost} from "../../../api/request";
import AppLocalesStorage from "../../../languages/AppLocalesStorage";
import TokenStorage from '../../../api/TokenStorage';
import {BallTriangle} from "react-loader-spinner";
import messages from '../../../languages/messages';

const AdultTest = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [questionsAmount, setQuestionsAmount] = useState(13);
    const [answers, setAnswers] = useState(new Array(questionsAmount).fill(''));
    const [enableColors, setEnableColors] = useState(false);

    const [infoTextId, setInfoTextId] = useState('thankForTest');
    const [errMsgVisible, setErrMsgVisible] = useState(false);
    const [isInputCorrect, setIsInputCorrect] = useState(false);

    const domSizesStore = useContext(DomSizesContext);
    const resultColors = [
        {bg: '#F44336', fg: '#fff'},
        {bg: '#FDD835', fg: '#fff'},
        {bg: '#2196F3', fg: '#fff'},
        {bg: '#8BC34A', fg: '#fff'},
        {bg: '#F06292', fg: '#fff'},
        {bg: '#FFFFFF', fg: '#212121'},
        {bg: '#9C27B0', fg: '#fff'},
        {bg: '#FF9800', fg: '#fff'},
        {bg: '#9E9E9E', fg: '#fff'},
        {bg: '#795548', fg: '#fff'},
        {bg: '#F754E1', fg: '#fff'},
        {bg: '#212121', fg: '#fff'},
        {
            bg: 'linear-gradient(90deg, rgba(236,47,75,0.7682423311121324) 0%, rgba(0,159,255,0.7502591378348214) 52%, rgba(42,245,152,0.7570378493194152) 100%)',
            fg: '#fff'
        },
    ];

    const navigate = useNavigate();

    const someValueRepeats = (answerList) => {
        let answerNumbs = answerList.filter((item) => item >= '0' && item <= '9');
        let result = false;

        for (let i = 0; i < answerNumbs.length; i++) {
            for (let j = i + 1; j < answerNumbs.length; j++) {
                if (answerNumbs[i] === answerNumbs[j]) {
                    result = true;
                }
            }
        }

        return result;
    };

    const checkCorrectInput = (answerList) => {
        let res = true;

        if (someValueRepeats(answerList)) {
            res = false;
            setInfoTextId('answerContainsRepeats');
            setErrMsgVisible(true);
        } else {
            setInfoTextId('thankForTest');
            setErrMsgVisible(false);
        }

        if (!answerList.every((item) => item >= '0' && item <= '9')) {
            res = false;
        }

        return res;
    };

    const typeAnswer = (evt, ind) => {
        let answersList = [...answers];
        let newVal = evt.target.value.trim();
        answersList[ind] = newVal;

        if (checkCorrectInput(answersList)) {
            setIsInputCorrect(true);
        } else {
            setIsInputCorrect(false);
        }

        setAnswers(answersList);
        setEnableColors(false);
    };

    const checkResult = (result) => {
        let isCorrect = true;

        result.forEach((item, ind) => {
            if (item !== ind + 1) {
                isCorrect = false;
            }
        });

        return isCorrect;
    };


    const getU = useGet();
    const postU = usePost();
    const languageFromLocalStore = AppLocalesStorage.getLocale();

    const sendData = async () => {
        let sortedAnswers = answers
            .map((item) => parseInt(item))
            .sort((x, y) => x > y ? 1 : x < y ? -1 : 0);

        let answersToSend = answers
            .map((item) => parseInt(item));

        const user = TokenStorage.getUser();

        if (checkResult(sortedAnswers)) {
            setEnableColors(true);

            setTimeout(async () => {
                setIsLoading(true)

                let lang = '62c984e56bb938e21db31692';

                await getU('api/languages')
                    .then((response) => {
                        const {_id} = response.data.languages.find(lang => lang.title === languageFromLocalStore)
                        lang = _id
                    });                

                const payload = {
                    isAdult: true,
                    order: answersToSend,
                    language: lang,
                    testId: '62f10f6955a8faabe2e88d58'
                };

                await postU(`api/tests/proficiency/${user.id}`, payload)
                    .then((response) => {
                        if (response.status === 'success') {
                            const resultStatus = response.data.resultStatus;
                            console.log("success")
                            navigate('/app/test-passed', {state: {resultStatus}})
                        }
                        if (response.status === 'fail' && response.message === 'Сперва необходимо оплатить тест') {
                            navigate('/paymentSelector')
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 400) {
                            window.location.replace('paymentSelector')
                        } else {
                            setErrMsgVisible(true);
                            setInfoTextId('serverError')
                        }
                    })
                    .finally(() =>{
                        setIsLoading(false)
                    })

            }, 2000);

        } else {
            setInfoTextId('typeCorrectNumb');
            setErrMsgVisible(true);
        };
    };

    if (isLoading) {
        return (
            <section>
                <h1
                    style={{
                        width: "90%",
                        height: "max-content",
                        margin: "25px auto",
                        fontWeight: "bold",
                    }}
                >
                    {messages[languageFromLocalStore].expectationResult}
                </h1>
                <div className="loader">
                    <BallTriangle
                        height="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    />
                </div>
            </section>
        );
    };

    return (
        <div className="test-block" style={{minHeight: `calc(100vh - ${domSizesStore.domSizes.headerHeight}px)`}}>

            <div className="test-header">
                <div className="test-header__content content-wrapper">

                    <div className="test-header__logo">
                        <div className="logo">
                            <Logo/>
                        </div>
                    </div>

                    <div className="test-header__lang">
                        <LangSelect listPosition="bottom"/>
                    </div>

                </div>
            </div>

            <div className="test">
                <div className="content-wrapper">

                    <div className="test__descr">
                        <h2 className="test__name">
                            <FormattedMessage id="testing"/>
                        </h2>

                        <p className="test__about">
                            <FormattedMessage id="typeAllNubmersNotThink"/>
                        </p>
                    </div>

                    <div className="test__answer">
                        <div className="answer-grid">
                            {
                                answers.map((answer, ind) => {
                                    return <div className="answer-grid__item" key={ind}>
                                        <InputMask
                                            mask="99"
                                            maskChar=" "
                                            value={answers[ind]}
                                            onChange={(evt) => typeAnswer(evt, ind)}
                                        >
                                            {(inputProps) => <input
                                                type="text"
                                                className="site-input"
                                                {...inputProps}
                                                style={enableColors ? {
                                                    background: resultColors[ind].bg,
                                                    color: resultColors[ind].fg,
                                                } : {}}
                                            />}
                                        </InputMask>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>

            <div className="test-result">
                <div className="content-wrapper">

                    <div className="send-result">
                        <div className="send-result__btn">
                            <button className="site-button"
                                    disabled={!isInputCorrect}
                                    onClick={sendData}
                            >
                                <FormattedMessage id="send"/>
                            </button>
                        </div>

                        <div className="send-result__txt">
                            <p style={{color: 'red', display: errMsgVisible ? 'block' : 'none'}}>
                                <FormattedMessage id={infoTextId}/>
                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default AdultTest;
