import React, { useState } from 'react';
import infoIcon from './../../images/info.svg';
import arrowIcon from './../../images/arrow.svg';
import './InfoButton.css';

const InfoButton = ({ clickCallback }) => {
    const [clickedState, setClickedState] = useState(false);

    const clicked = () => {
        setClickedState(!clickedState);
        clickCallback();
    };

    return (
        <button className={`info-button ${clickedState ? 'info-button--clicked' : ''}`} onClick={clicked}>
            <span className="info-button__icon">
                <img src={infoIcon} alt="info icon" />
            </span>

            <span className="info-button__arrow">
                <img src={arrowIcon} alt="arrow icon" style={{ transform: clickedState ? 'rotate(180deg)' : '' }} />
            </span>
        </button>
    );
};

export default InfoButton;
