import React from 'react';
import SubHeader from "../../../components/SubHeader/SubHeader";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import './PaymentSuccess.css';
import connection from "../../../images/paymentSelector/secureConnection.svg";
import maestro from "../../../images/paymentSelector/maestro.svg";
import mirAccept from "../../../images/paymentSelector/mirAccept.svg";
import psiDss from "../../../images/paymentSelector/psiDss.svg";
import mastercardBottom from "../../../images/paymentSelector/mastercardBottom.svg";
import visaBottom from "../../../images/paymentSelector/visaBottom.svg";
import backFont  from '../../../images/undefined.png'

const PaymentError = () => {


    return (
        <div className="payment" >
            <SubHeader/>
            <div className="content-wrapper" style={{
                // backgroundImage: `url(${backFont})`,
                // backgroundImage: `url(https://image.shutterstock.com/shutterstock/photos/2090139355/display_1500/stock-vector-people-are-jumping-merrily-with-a-victory-cup-in-their-hands-vector-illustration-in-a-flat-style-2090139355.jpg)`,
                // backgroundRepeat: 'no-repeat',
                // backgroundAttachment: 'fixed',
                // backgroundPosition: 'center center',
                // backgroundSize: 'cover',
            }}>
                <div className="payment__content">

                    <h2 className="payment__title site-header">
                        <FormattedMessage id="paymentSuccess"/>
                    </h2>

                    <h2 className="payment__desc site-header">
                        <FormattedMessage id="tyForPay"/>
                    </h2>

                    <div className="payment__form">

                        <Link to="/app/children-test">
                            <button className="site-button payment__submit">
                                <FormattedMessage id="goToKidTest"/>
                            </button>
                        </Link>
                        <Link to="/app/adult-test">
                            <button className="site-button payment__submit">
                                <FormattedMessage id="goToAdultTest"/>
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="bottom__icons">
                    <img src={connection}/>
                    <img src={maestro}/>
                    <img src={mirAccept}/>
                    <img src={psiDss}/>
                    <img src={mastercardBottom}/>
                    <img src={visaBottom}/>
                </div>
            </div>
        </div>
    );
};

export default PaymentError;
