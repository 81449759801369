import {Navigate} from "react-router-dom";
import MainLayout from "./pages/MainLayout";
import SignUpContacts from "./pages/SignUp/SignUpContacts/SignUpContacts";
import SignUpSMS from "./pages/SignUp/SignUpSMS/SignUpSMS";
import SignUpName from "./pages/SignUp/SignUpName/SignUpName";
import Payment from "./pages/Payment/Payment";
import AdultTest from './pages/Tests/AdultTest/AdultTest';
import TestPassed from "./pages/TestPassed/TestPassed";
import NotFound from './pages/NotFound';
import PaymantSelection from "./pages/Payment/PaymantSelection/PaymantSelection";
import PaymentError from "./pages/Payment/PaymentError/PaymentError";
import PaymentSuccess from "./pages/Payment/PaymentSuccess/PaymentSuccess";
import PaymentResult from "./pages/Payment/PaymentResult/PaymentResult";
import CantPassTest from "./pages/Payment/CantPassTest/CantPassTest";
import ChildrenTest from "./pages/Tests/ChildrenTest/ChildrenTest";
import Diagnostic from "./pages/Diagnostic/Diagnostic";
import Consultation from "./pages/Consultation/Consultation";

const routes = [
    {
        path: 'app',
        element: <MainLayout/>,
        children: [
            {path: 'sign-up-name', element: <SignUpName/>},
            {path: 'paymentSelector', element: <PaymantSelection/>},
            {path: 'payment', element: <Payment/>},
            {path: 'paymentResult', element: <PaymentResult/>},
            {path: 'cantPassTest', element: <CantPassTest/>},
            {path: 'adult-test', element: <AdultTest/>},
            {path: 'children-test', element: <ChildrenTest/>},
            {path: 'test', element: <AdultTest/>},
            {path: 'test-passed', element: <TestPassed/>},
        ],
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {
                path: 'order',
                children: [
                   {path: 'allDiagnostic/:id', element: <Diagnostic />},
                   {path: 'consultation/:id', element: <Consultation />},
                ],
            },
            {path: 'sign-up-contacts', element: <SignUpContacts/>},
            {path: 'sign-up-sms', element: <SignUpSMS/>},
            {path: '/', element: <Navigate to="/sign-up-contacts"/>},
            {path: '*', element: <NotFound/>},
        ],
    },
];


export default routes;
