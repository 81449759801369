import React from 'react';
import SubHeader from "../../../components/SubHeader/SubHeader";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import './PaymentError.css';
import connection from "../../../images/paymentSelector/secureConnection.svg";
import maestro from "../../../images/paymentSelector/maestro.svg";
import mirAccept from "../../../images/paymentSelector/mirAccept.svg";
import psiDss from "../../../images/paymentSelector/psiDss.svg";
import mastercardBottom from "../../../images/paymentSelector/mastercardBottom.svg";
import visaBottom from "../../../images/paymentSelector/visaBottom.svg";
import {useLocation} from "react-router";

const PaymentError = () => {

    return (
        <div className="payment">
            <SubHeader/>
            <div className="content-wrapper">
                <div className="payment__content">
                    <h2 className="payment__title site-header">
                        <FormattedMessage id="paymentError"/>
                    </h2>

                    <h2 className="payment__text__error">
                        <FormattedMessage id="paymentWasNotMade"/>
                    </h2>

                    <h2 className="payment__text__error">
                        <FormattedMessage id="tryAgain"/>
                    </h2>

                    <div className="payment__form">


                        <Link to="/app/paymentSelector">
                            <button className="site-button payment__submit">
                                <FormattedMessage id="repeat"/>
                            </button>
                        </Link>
                    </div>

                </div>
                <div className="bottom__icons">
                    <img src={connection}/>
                    <img src={maestro}/>
                    <img src={mirAccept}/>
                    <img src={psiDss}/>
                    <img src={mastercardBottom}/>
                    <img src={visaBottom}/>
                </div>
            </div>
        </div>
    );
};

export default PaymentError;
