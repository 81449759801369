import React, { useState, useEffect } from "react";
import logoENG from './../../images/logos/eng.png';

const Logo = () => {
    const [curLogo, setCurLogo] = useState();

    useEffect(() => {
        setCurLogo(logoENG);
    }, []);

    return (
        <img src={curLogo} alt="site logo" />
    );
};

export default Logo;