import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InputMask from 'react-input-mask';
import CardSelect from '../../components/CardSelect/CardSelect';
import SubHeader from '../../components/SubHeader/SubHeader';
import './Payment.css';
import { Link } from 'react-router-dom';

const Payment = () => {
    const [paySum, setPaySum] = useState();
    const [userCard, setUserCard] = useState('');
    const intl = useIntl();

    const paySumInput = (evt) => {
        const newVal = evt.target.value;
        setPaySum(newVal);
    };

    return (
        <div className="payment">
            <SubHeader />

            <div className="content-wrapper">
                <div className="payment__content">
                    <h2 className="payment__title site-header">
                        <FormattedMessage id="requisites" />
                    </h2>

                    <form className="payment__form">
                        <input
                            type="text"
                            className="site-input payment__input"
                            placeholder={intl.formatMessage({ id: 'sumToPay' })}
                            value={paySum}
                            onChange={paySumInput}
                        />

                        <div className="payment__card-input">
                            {/*<div className="card-select-wrap">*/}
                            {/*    <CardSelect />*/}
                            {/*</div>*/}

                            <InputMask
                                disableUnderline
                                mask="9999 9999 9999 9999"
                                maskChar=" "
                                value={userCard}
                                onChange={(evt) => setUserCard(evt.target.value)}
                            >
                                {(inputProps) =>
                                    <input
                                        placeholder={intl.formatMessage({ id: 'cardNumber' })}
                                        type="text"
                                        className="site-input payment__input payment-input--card" {...inputProps} />}
                            </InputMask>
                        </div>

                        <Link to="/test">
                            <button className="site-button payment__submit">
                                <FormattedMessage id="pay" />
                            </button>
                        </Link>

                        {/*<div className="payment__add-card">*/}
                        {/*    <a href="#">*/}
                        {/*        <FormattedMessage id="addCard" />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Payment;
