import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import InputMask from 'react-input-mask';
import CardSelect from '../../../components/CardSelect/CardSelect';
import SubHeader from '../../../components/SubHeader/SubHeader';
import './PaymentSelection.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import pauPall from '../../../images/paymentSelector/PayPalIcons.svg'
import visa from '../../../images/paymentSelector/visa.svg'
import masterCard from '../../../images/paymentSelector/masterCard.svg'
import {setCategoryId} from "../../../redux/slices/payment";
import {useDispatch, useSelector} from "react-redux";
import psiDss from '../../../images/paymentSelector/psiDss.svg'
import mirAccept from '../../../images/paymentSelector/mirAccept.svg'
import maestro from '../../../images/paymentSelector/maestro.svg'
import mastercardBottom from '../../../images/paymentSelector/mastercardBottom.svg'
import visaBottom from '../../../images/paymentSelector/visaBottom.svg'
import connection from '../../../images/paymentSelector/secureConnection.svg'
import {get, post, useGet, usePost} from '../../../api/request'
import TokenStorage from "../../../api/TokenStorage";
import {BallTriangle} from "react-loader-spinner";
import AppLocalesStorage from '../../../languages/AppLocalesStorage';

const PaymentSelection = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const {categoryId} = useSelector((state) => state.payment);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [serverError, setServerError] = useState(false);

    const onChangeCategory = useCallback((id) => {
        dispatch(setCategoryId(id))
    }, [])

    const getU = useGet();
    const postU = usePost();

    const kindOfPayment = [
        {
            title: <FormattedMessage id="paymentByCard"/>,
            icon: [visa, masterCard],
            disable: false
        },
        {
            title: <FormattedMessage id="payByPayPall"/>,
            icon: [pauPall],
            disable: true
        },
    ]

    const hostUrl = window.location.origin.toString()
    const callbackLink = `${hostUrl}/app/paymentResult`;

    console.log(AppLocalesStorage.getLang())
    const goToPayment = () => {
        setIsLoaded(true)
        setServerError(false)

        postU(
            `api/tests/proficiency/payments`, 
            {
                callbackLink: callbackLink,
                language: AppLocalesStorage.getLang(),
            },
        )
            .then((response) => {
                if (response.status !== 'success') {
                    throw new Error("Произошла ошибка");
                }

                if("continue" in response.data && response.data.continue === true) {
                    window.location.href = `${hostUrl}/app/paymentResult?success=true`;
                    return;
                }

                window.location.href = response.data.url;
            })
            .catch((error) => {
                console.log("not good");
                console.log(error);
                if (error.response.data.message === "Вы ответили на этот тест") {
                    window.location.href = `${hostUrl}/app/cantPassTest`
                } else if (error.response.data.message === "Вы уже оплатили тест") {
                    window.location.href = `${hostUrl}/app/paymentResult?success=true`
                } else {
                    setServerError(true)
                }
            })
            .finally(() =>{
                setIsLoaded(false)
            })
    }

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <div className="payment">
            <SubHeader/>

            <div className="content-wrapper">
                <div className="payment__content">
                    <h2 className="payment__title site-header">
                        <FormattedMessage id="paymentPay"/>
                    </h2>

                    <h2 className="payment__text site-header">
                        <FormattedMessage id="choosePaymentMethod"/>
                    </h2>

                    <div className="payment__form">

                        {
                            kindOfPayment.map((item, index) => (
                                <button disabled={item.disable}
                                        onClick={() => onChangeCategory(index)}
                                        key={index}
                                        className={`radio__button ${categoryId === index ? 'active' : ''}`}
                                >
                                    <p className="radio__button-text">
                                        {
                                            item.title
                                        }
                                    </p>
                                    <div>
                                        {
                                            item.icon.map((item, index) => <img key={index} src={item}/>)
                                        }
                                    </div>
                                </button>
                            ))
                        }

                        {/*<Link to="/app/paymentResult?success=true">*/}
                        {/*    <button className="site-button payment__submit">*/}
                        {/*        <FormattedMessage id="goToPayment"/>*/}
                        {/*    </button>*/}
                        {/*</Link>*/}

                        <div className="site-button payment__submit" onClick={goToPayment}>

                            <FormattedMessage id="goToPayment"/>

                        </div>
                        {serverError &&
                        <div className="server-error">
                            <FormattedMessage id="serverError"/>
                        </div>
                        }
                    </div>

                </div>
                <div className="bottom__icons">
                    <img src={connection}/>
                    <img src={maestro}/>
                    <img src={mirAccept}/>
                    <img src={psiDss}/>
                    <img src={mastercardBottom}/>
                    <img src={visaBottom}/>
                </div>
            </div>
        </div>
    );
};

export default PaymentSelection;
