const defaultENV = "prod"; // local | dev | prod

const baseURLProd = "https://api.toknowyourself.online";
const baseURLDev = "https://api.dev.toknowyourself.online/"; 
const baseURLLocal = "http://localhost:3080";

const ENV = {
    prod: {
        apiUrl: baseURLProd,
    },
	dev: {
        apiUrl: baseURLDev,
    },
	local: {
        apiUrl: baseURLLocal,
    },
};

const getEnvVars = () => {
    return ENV[defaultENV];
};

export default getEnvVars;
